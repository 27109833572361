import axios from "../../utils/axios";
import { put } from 'redux-saga/effects'
import {VIP_LIST,OPERATOR_LIST,SALE_LIST,SALE_INFO,CARD_INFO,CARD_LIST,CARD_ACTIVE,MEAL_LIST,MEAL_INFO,COMPANY_SELL, CARD_OPERATE, ORDER_CONFIRM, PAY_IMAGE , COMPANY_SELL_CODE , CANCEL_ACTIVE
} from './actionTypes'
import { liliao_vip, user_operator , sale_list , sale_list_info , card_info , card_list , card_active , card_operate, meal_list , meal_info ,company_sell , order_confirm , pay_images , company_sell_code , cancel_active } from "../../utils/api";

// 会员列表请求
export function* postVipList(active) {
    const result = yield axios(`/kfApi/${liliao_vip}`,active.params,'POST');
    if(result.code===1){
        const data = result.data;
        yield put({
            type: VIP_LIST.POST_LIST,
            data
        }) 
    }
}

// 操作员列表
export function* getOperatorList(active) {
    const result = yield axios(`/kfApi/${user_operator}`,active.params);
    if(result.code===1){
        const data = result.data;
        yield put({
            type: OPERATOR_LIST.GET_LIST,
            data
        }) 
    }
}

// 销售(或购买)订单列表
export function* postSaleList(active) {
    const result = yield axios(`/kfApi/${sale_list}`,active.params,'POST');
    if(result.code===1){
        const data = result.data;
        yield put({
            type: SALE_LIST.POST_LIST,
            data
        }) 
    }
}

// 订单详情
export function* postSaleInfo(active) {
    const result = yield axios(`/kfApi/${sale_list_info}`,active.params , 'POST');
    if(result.code===1){
        const data = result.data;
        yield put({
            type: SALE_INFO.POST_INFO,
            data
        }) 
    }
}

// 单卡详情
export function* postCardInfo(active) {
    const result = yield axios(`/kfApi/${card_info}`,active.params );
    if(result.code===1){
        const data = result.data;
        yield put({
            type: CARD_INFO.POST_INFO,
            data
        }) 
    }
}

// 卡列表
export function* getCardList(active) {
    const result = yield axios(`/kfApi/${card_list}`,active.params , 'POST' );
    if(result.code===1){
        const data = result.data;
        yield put({
            type: CARD_LIST.GET_LIST,
            data,
            params:active.params
        }) 
    }
}

// 卡激活
export function* postCardActive(active) {
    const result = yield axios(`/kfApi/${card_active}`,active.params , 'POST' );
    if(result.code===1){
        const data = result.data;
        yield put({
            type: CARD_ACTIVE.POST_ACTIVE,
            data
        }) 
    }
}

// 套餐列表
export function* postMealList(active) {
    const result = yield axios(`/kfApi/${meal_list}`,active.params );
    if(result.code===1){
        const data = result.data;
        yield put({
            type: MEAL_LIST.POST_LIST,
            data
        }) 
    }
}

// 服务套餐详情
export function* postMealInfo(active) {
    const result = yield axios(`/kfApi/${meal_info}`,active.params  );
    if(result.code===1){
        const data = result.data;
        yield put({
            type: MEAL_INFO.POST_INFO,
            data
        }) 
    }
}

// 套餐销售
export function* postCompanySell(active) {
    const result = yield axios(`/kfApi/${company_sell}`,active.params , 'POST' );
    if(result.code===1){
        const data = result.data;
        yield put({
            type: COMPANY_SELL.POST_SELL,
            data
        }) 
    }
}

// 服务操作记录(明细)列表
export function* postCardOperate(active) {
    const result = yield axios(`/kfApi/${card_operate}`,active.params , 'POST' );
    if(result.code===1){
        const data = result.data;
        yield put({
            type: CARD_OPERATE.POST_OPERATE,
            data
        }) 
    }
}

// 确认支付
export function* getOrderConfirm(active) {
    const result = yield axios(`/kfApi/${order_confirm}`,active.params );
    if(result.code===1){
        const data = result.data;
        yield put({
            type: ORDER_CONFIRM.GET_CONFIRM,
            data
        }) 
    }
}

// 获取支付图片
export function* getPayImage(active) {
    const result = yield axios(`/kfApi/${pay_images}`,active.params );
    if(result.code===1){
        const data = result.data;
        yield put({
            type: PAY_IMAGE.GET_PAY_IMAGE,
            data
        }) 
    }
}

// 销售卡
export function* postCompanySellCode(active) {
    const result = yield axios(`/kfApi/${company_sell_code}`,active.params, 'POST' );
    if(result.code===1){
        const data = result.data;
        yield put({
            type: COMPANY_SELL_CODE.POST_COMPANY_SELL_CODE,
            data
        }) 
    }
}

// 撤销激活
export function* postCancelActive(active) {
    const result = yield axios(`/kfApi/${cancel_active}`,active.params, 'POST' );
    if(result.code===1){
        const data = result.data;
        yield put({
            type: CANCEL_ACTIVE.POST_CANCEL_ACTIVE,
            data
        }) 
    }
}

