// 根据患者id来获取用户套餐列表
export const USER_MEAL = {
    MEAL:'USER_MEAL',
    GET_MEAL:'POST_USER_MEAL'
};

// 获取患者套餐内的服务列表
export const USER_SERVICE = {
    SERVICE:'USER_SERVICE',
    POST_SERVICE:'POST_USER_SERVICE',
    POST_SERVICE_DTDA:'POST_SERVICE_DTDA'
};

// 患者套餐绑定专项理疗(病名)
export const BIND_DISEASE = {
    DISEASE:'BIND_DISEASE',
    GET_DISEASE:'GET_BIND_DISEASE'
};

// 操作患者套餐内的服务
export const SERVICE_OPERATE = {
    OPERATE:'SERVICE_OPERATE',
    POST_SERVICE_OPERATE:'POST_SERVICE_OPERATE',
    REPORT:'REPORT'
};

//数据字典
export const DICTIONARIES = {
    DICTIONARIES:'DICTIONARIES',
    GET_DICTIONARIES:'GET_DICTIONARIES'
};

//修改 状态
export const MODIFY = "MODIFY";

//撤销
export const MEAL_REVOKE = {
    REVOKE:'MEAL_REVOKE',
    GET_MEAL_REVOKE:'GET_REVOKE'
};


//确定
export const MEAL_DEFINE = {
    DEFINE:'DEFINE',
    GET_MEAL_DEFINE:'GET_MEAL_DEFINE'
};

export const SERVICE_LIST = {
    SERVICE_LIST:'SERVICE_LIST',
    GET_SERVICE_LIST:'GET_SERVICE_LIST'
};

//oss
export const OSS = {
    IMG:'IMG',
    GET_IMG:'GET_IMG',
    POST_IMG:'POST_IMG'
};