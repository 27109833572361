//登录
export const admin_login = "admin/login";
//验证码
export const code_img = "captcha";
//用户列表
export const users = "new_customer";
//病名列表
export const disease_name = "config/zzbkf_disease_name";
//根据主诉和病名找症状（单选）
export const get_symptoms = "zzbkf/get_symptoms";
//根据单个症状id获取辨别症状列表（多选）
export const get_distinguish_symptoms = "zzbkf/get_distinguish_symptoms";
//提交报告
export const submit = "zzbkf/submit";
//报告详情
export const report_info = "ptkf/info";
//完诊
export const final = "zzbkf/final";
//就诊记录
export const list = "ptkf/list";
//新建用户
export const new_user = "new_user";
//相关搜索
export const search = "config/search";
//相关搜索
export const symptoms = "zzbkf/add_symptoms";
//获取所有科室
export const keshi = "ptkf/get_keshi";
//根据科室id获取所有的病名列表(单选)
export const disease = "ptkf/get_disease";
//根据病名获取所有的症状列表(多选)
export const liliao_symptoms = "ptkf/get_symptoms";
//理疗提交报告
export const liliao_submit = "ptkf/submit";

//会员列表
export const liliao_vip = "admin/user/user_list";
//操作员列表
export const user_operator = "user/operator";
//销售(或购买)订单列表
export const sale_list = "admin/order/list";
//销售记录详情(获取订单具体详情)
export const sale_list_info = "admin/order/get_desc_list";
//单卡基本详情
export const card_info = "admin/card/info";
//卡列表
export const card_list = "admin/card/list";
//卡激活
export const card_active = "admin/card/active";
//撤销激活
export const cancel_active = "admin/card/cancel_active";
//卡销售
export const company_sell_code = "admin/card/company_sell";
//服务操作记录(明细)列表
export const card_operate = "admin/set_meal/operate_list";
//服务套餐列表
export const meal_list = "admin/set_meal/list";
//获取系统套餐详情及服务列表
export const meal_info = "admin/set_meal/info";
//销售套餐(租户端)
export const company_sell = "admin/set_meal/company_sell";
//确认支付
export const order_confirm = "admin/order/confirm_pay";
// 根据患者id来获取用户套餐列表
export const user_set_meal = "admin/set_meal/user_set_meal";
// 获取患者套餐内的服务列表
export const user_service = "admin/set_meal/user_service";
// 患者套餐绑定专项理疗(病名)
export const bind_disease = "admin/set_meal/bind_disease";
// 操作患者套餐内的服务
export const user_service_operate = "admin/set_meal/user_service_operate";
// 获取系统字典列表
export const dictionaries = "dictionaries";
// 卡销售或套餐取消订单(租户端)
export const meal_cancel = "admin/order/cancel";
// 卡销售或套餐确定支付(租户端)
export const confirm_pay = "admin/order/confirm_pay";
// 获取支付图片
export const pay_images = "admin/pay_images";
// 租户端用户预约的列表
export const subscribe_list = "admin/subscribe/list";
// 取消预约
export const subscribe_cancel = "admin/subscribe/cancel";
// 更新/修改预约
export const subscribe_update = "admin/subscribe/update";
//门店列表
export const store = "admin/subscribe/organization";
//获取服务项目列表
export const service_list = "admin/service/list";
//获取账户信息
export const info = "admin/account/info";
//上传oss
export const oss = "report/oss";
//预约时间列表
export const time_list = "admin/subscribe/time_list";
//天际预约时间
export const time_add = "admin/subscribe/time_add";