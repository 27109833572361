import {
    VIP_LIST,
    OPERATOR_LIST,
    SALE_LIST,
    SALE_INFO,
    CARD_INFO,
    CARD_LIST,
    CARD_ACTIVE,
    MEAL_LIST,
    MEAL_INFO,
    COMPANY_SELL,
    MODIFY,
    CARD_OPERATE,
    ORDER_CONFIRM,
    PAY_IMAGE,
    COMPANY_SELL_CODE,
    CANCEL_ACTIVE
} from './actionTypes'
import { message } from 'antd';

// 数据池
const defaultState = {
    users: null,      //用户列表
    operator:[],      //操作员列表
    sales:null,       //销售订单列表
    sale_info:null,   //订单详情  
    card_info:null,   //单卡详情 
    card_list:[],     //卡列表
    card_total:0,
    meal_list:[],     //套餐列表
    meal_info:null,   //套餐详情
    operate_list:[],  //操作列表
    card_show:false,   //卡详情的显示/隐藏
    pay_show:false,
    order_code:0,
    pay_image:''
};

var Reducer = (state = defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state));
    switch(action.type){
        case VIP_LIST.POST_LIST:          //客户列表
            newState.users = action.data;
            return newState;
        case OPERATOR_LIST.GET_LIST:      //操作员列表
            let operator = []
            action.data.map((act)=>{
                if(act.account!=='admin'){
                    operator.push(act);
                }
            })
            newState.operator = operator;
            return newState;
        case SALE_LIST.POST_LIST:         //销售(或购买)订单列表
            newState.sales = action.data;
            return newState;
        case SALE_INFO.POST_INFO:         //订单详情
            newState.sale_info = action.data;
            return newState;
        case CARD_INFO.POST_INFO:         //单卡详情
            newState.card_info = action.data;
            return newState;
        case CARD_LIST.GET_LIST:         //卡列表
            if(action.params.push){
                if(action.data.list.length>0){
                    newState.card_list = newState.card_list.concat(action.data.list);
                    newState.card_total = action.data.total;
                }else{
                    message.error('没找到这张卡！');
                }
            }else{
                newState.card_list = action.data.list;
                newState.card_total = action.data.total;
            }
            return newState;
        case CARD_ACTIVE.POST_ACTIVE:         //卡激活
            message.success('激活成功！');
            setTimeout(function(){
                window.history.go(0);
            },1000);
            return newState;
        case MEAL_LIST.POST_LIST:         //服务套餐列表
            let meal_list = [];
            action.data.map((meal)=>{
                if(meal.stock_count-meal.stock_use_count!==0){
                    meal_list.push(meal);
                }
            })
            newState.meal_list = meal_list;
            return newState;
        case MEAL_INFO.POST_INFO:          //服务套餐详情
            newState.meal_info = action.data;
            return newState;
        case COMPANY_SELL.POST_SELL:         //销售
            newState.pay_show = true;
            newState.order_code = action.data.order_code;
            return newState;
        case MODIFY:                         //修改 状态
            action.params.map((item)=>{
                newState[item.key] = item.value;
            })
            return newState;
        case CARD_OPERATE.POST_OPERATE:      //操作列表
            newState.operate_list = action.data;
            return newState;
        case ORDER_CONFIRM.GET_CONFIRM:      //确认支付
            newState.pay_show = false;
            newState.order_code = 0;
            message.success('支付成功！');
            setTimeout(function(){
                window.history.go(0);
            },1000);
            return newState;
        case PAY_IMAGE.GET_PAY_IMAGE:      //获取支付图片
            newState.pay_image = action.data;
            return newState;
        case COMPANY_SELL_CODE.POST_COMPANY_SELL_CODE:      //卡销售
            newState.pay_show = true;
            newState.order_code = action.data.order_code;
            return newState;
        case CANCEL_ACTIVE.POST_CANCEL_ACTIVE:      //撤销激活
            message.success('撤销成功！');
            setTimeout(function(){
                window.history.go(0);
            },1000);
            return newState;
        default:
            return state;
    }
};

export default Reducer;
