import { combineReducers } from 'redux';

import homeReducer from './home/reducer';
import loginReducer from './login/reducer';
import reportReducer from './report/reducer';
import listReducer from './list/reducer';
import operateReducer from './operate/reducer';
import subscribeReducer from './subscribe/reducer';

const Reducer = combineReducers({
    homeReducer,
    loginReducer,
    reportReducer,
    listReducer,
    operateReducer,
    subscribeReducer
});

export default Reducer;
  