import {
    USER_MEAL,
    USER_SERVICE,
    BIND_DISEASE,
    SERVICE_OPERATE,
    DICTIONARIES,
    MODIFY,
    MEAL_DEFINE,
    MEAL_REVOKE,
    SERVICE_LIST,
    OSS
} from './actionTypes';
import { message } from 'antd';

// 数据池
const defaultState = {
    user_meal:null,
    user_service:null,
    typing:{
        show:false,
        sign:false,
        disease:null,
        active:0  
    },
    report_token:0,
    service_operate:null,
    dictionaries:[],
    defineShow:false,
    teaShow:false,
    service_list:[],
    oss:null
};

var Reducer = (state = defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state));
    switch(action.type){
        case USER_MEAL.GET_MEAL:                 //客户列表
            newState.user_meal = action.data;
            return newState;
        case USER_SERVICE.POST_SERVICE:          //正常返回
            newState.user_service = action.data.service;
            newState.report_token = action.data.report_token;
            return newState;
        case USER_SERVICE.POST_SERVICE_DTDA:          //需要进行专项理疗绑定
            //弹框
            newState.typing = {
                sign:true,
                disease:action.data.disease,
                active:0
            }
            return newState;
        case BIND_DISEASE.GET_DISEASE:           //绑定分型
            newState.disease = action.data;
            let typing = newState.typing;
            typing.show = false;
            newState.typing = typing;
            return newState;
        case SERVICE_OPERATE.POST_SERVICE_OPERATE:           //客户列表
            newState.teaShow = false;
            newState.defineShow = false;
            message.success('操作成功！');
            return newState;
        case DICTIONARIES.GET_DICTIONARIES:           //客户列表
            let dictionaries = [];
            action.data.dictionaries[0].children.map((diction)=>{
                dictionaries.push({
                    ...diction,
                    counts:0
                })
            })
            newState.dictionaries = dictionaries;
            return newState;
        case MODIFY:                         //修改 状态
            action.params.map((item)=>{
                newState[item.key] = item.value;
            })
            return newState;
        case SERVICE_OPERATE.REPORT:
            newState.teaShow = false;
            newState.defineShow = false;
            message.success('操作成功！');
            return newState;
        case MEAL_REVOKE.GET_MEAL_REVOKE:
            message.success('操作成功！');
            window.history.go(0);
            return newState; 
        case SERVICE_LIST.GET_SERVICE_LIST:         //项目列表
            newState.service_list = action.data;
            return newState;
        case OSS.POST_IMG:       //上传OSS
            newState.oss = action.data;
            let typing1 = newState.typing;
            typing1.sign = false;
            typing1.show = true;
            newState.typing = typing1;
            return newState;
        default:
            return state;
    }
};

export default Reducer;


